<template>
  <!--设备统计 -->
  <div class="equipmentStatis">
    <el-menu
      v-if="$route.query.type!=2"
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">全部设备</el-menu-item>
    </el-menu>
    <div class="datarow">
      <div class="block">
        <span class="demonstration">日期</span>
        <el-date-picker
          v-model="datevalue"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      
      </div>
      <div>
        <span class="demonstration">设备</span>
        <el-select v-model="shebeiSearchvalue" filterable placeholder="请选择">
          <el-option
            v-for="item in device_list"
            :key="item.id"
            :label="item.device_info"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div style="text-align:right;margin-bottom:10px">
      <el-button type="success" size="mini" @click="search()">查询</el-button>
      <el-button type="primary" size="mini" @click="reset()">重置</el-button>
    </div>
    <!-- 表格 -->
    <el-table :data="tableData" border style="width: 100%;max-height:61vh;overflow-Y:scroll">
      <el-table-column prop="device_number" label="设备编码"></el-table-column>
      <el-table-column prop="device_info" label="设备名称"></el-table-column>
      <el-table-column prop="is_use" label="设备是否使用">
        <template #default='scope'>
          <span>{{scope.row.is_use==1? '是' :'否'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="port_number" label="设备总端口数"></el-table-column>
      <el-table-column prop="created_at" label="设备添加时间"></el-table-column>
      <el-table-column prop="status" label="设备状态">
         <template #default="scope">
          <span>{{scope.row.status==0? '正常' :scope.row.status}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="month_charge" label="月均使用">
      </el-table-column>
      <el-table-column prop="total_charge" label="总共使用"></el-table-column>
    
    </el-table>
  </div>
</template>
<script>
import { deviceCount } from "../../request/api";
import { defineComponent, ref } from "vue";
export default {
  props: ["page", "pagepage"],
  data() {
    return {
      activeIndex: "1", //顶部菜单
      datevalue: ['',''], //日期
      address_list: [],
      device_list: [],
      addressvalue: "", //场地
      shebeiSearchvalue: "",
      labelPosition: "left", //展示是为详情还是趋势
      tableData: [],
      pageTotal: 0
    };
  },
  methods: {
    list() {
      return new Promise((resolve, reject) => {
        deviceCount({
          page: this.page,
          page_size: this.pagepage,
          start_time: this.datevalue[0],
          end_time:this.datevalue[1],
          device_id: this.shebeiSearchvalue,
          address_id: this.addressvalue
        }).then(res => {
          resolve(res.total)
          this.pageTotal=res.total
          this.tableData = res.list.list;
          this.tableData.forEach(item=>{
              item.month_charge= item.month_charge.toFixed(2)
          })
          this.address_list = res.list.address_list;
          this.device_list = res.list.device_list;
        });
      });
    },
    getTime (time) {
       let date = new Date(time );//时间戳为10位需*1000，时间戳为13位的话不需乘1000
       let  Y = date.getFullYear() + '-';
       let  M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
       let  D = date.getDate() + ' ';
       let  h = date.getHours() + ':';
        let m = date.getMinutes() + ':';
       let  s = date.getSeconds();
        return Y+M+D+h+m+s;
    },
   async  reset() {
      this.shebeiSearchvalue=''
      this.addressvalue=''
      this.datevalue=''
      await this.list()
      this.$emit("pagetotalClick", this.pageTotal);
    },
    async search() {
      if(this.datevalue[0]){
         this.datevalue[0]=this.getTime(this.datevalue[0])
      this.datevalue[1]=this.getTime(this.datevalue[1])
      }
     
      await this.list();
      this.$emit("pagetotalClick", this.pageTotal);
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key;
    }
  },
    watch: {
    page(newName, oldName) {
      this.list();
    },
    pagepage(newName, oldName) {
      this.list();
    }
  },
   async created() {
     this.pageTotal= await this.list();
     this.pageTotal?this.$emit("pagetotalClick", this.pageTotal):"";
  }
};
</script >
<style lang='less' scoped>
.datarow {
  display: flex;
  justify-content: space-between;
}
/deep/.has-gutter th {
  background-color: #f5f7fa;
}

/deep/.cell {
  text-align: center;
}
.yesonmon {
  color: #3f7ee2;
  line-height: 80px;
  text-align: center;
}
.datarow {
  padding: 20px 0;
  .demonstration {
    margin-right: 10px;
  }
}
</style>
